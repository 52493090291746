@font-face {
    font-family: 'Segoe UI Variable Text';
    src: local('Segoe UI Variable Text'),
        url('/assets/fonts/Segoe-UI-Variable-Static-Small-Semilight.ttf') format('truetype-variations');
    font-variation-settings: 'opsz' 10.5;
    font-weight: 300 700;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI Variable Text Bold';
    src: local('Segoe UI Variable Text Bold'),
        url('/assets/fonts/Segoe-UI-Variable-Static-Small-Semibold.ttf') format('truetype-variations');
    font-variation-settings: 'opsz' 10.5;
    font-weight: 300 700;
    font-style: italic;
}

@import '~primeicons/primeicons.css';

html,
body,
html * {

    font-family: "Segoe UI Variable Text", Helvetica, sans-serif ;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000;
    text-align: center;
    background-color: #dddbdba1;
}

.snackBarSuccess {
    background-color: rgb(68, 125, 199);
    width: 450px;
    font-weight: bold;
    .mat-simple-snackbar-action {
        font-weight: bold;
        color: white !important;
    }
  }
  
  .snackBarFailure {
    background-color: rgb(150, 20, 20);
    width: 620px;
  }
  
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

strong, h1, h2, h3, h4, h5 {
    font-family: "Segoe UI Variable Text Bold", Helvetica, sans-serif !important;
}

.p-paginator {
    background: #f3f3f3;
    color: #6c757d;
    border: solid #e9ecef;
    border-width: 0;
    padding: 0.5rem 1rem;
    border-radius: 3px;
}

.p-datepicker {
    padding: .5rem;
    background: #f3f3f3;
    color: #495057;
    border: none;
    border-radius: 3px;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #495057;
    background: #f3f3f3;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

